import ReactGA from 'react-ga4';
import env from './env';

const GA_MEASUREMENT_ID = env.gaMeasurementId;

const analytics = {
  initialize() {
    ReactGA.initialize(GA_MEASUREMENT_ID);
  },

  pageView(title: string) {
    ReactGA.event('page_view', {
      page_title: title,
      page_location: window.location.href,
    });
  },
};

export default analytics;
