import ProfilePicture from 'domain/models/ProfilePicture';
import Tag from 'domain/models/Tag';
import ThreeState from 'domain/models/ThreeState';
import { ActionCreator } from 'redux';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import Profile from '../../domain/models/Profile';
import User from '../../domain/models/User';
import api from '../../helpers/api';
import firebase from '../../helpers/firebase';
import { IBaseState } from '../reducers/baseReducer';

export enum BaseActionTypes {
  INIT_FIREBASE_AUTH_LISTENER = 'INIT_FIREBASE_AUTH_LISTENER',
  LOGOUT = 'LOGOUT',
  FETCH_CURRENT_USER = 'FETCH_CURRENT_USER',
  SET_CURRENT_USER = 'SET_CURRENT_USER',
  FETCH_TAG = 'FETCH_TAG',
  SET_TAG = 'SET_TAG',
  FETCH_PROFILE_BY_TAG_ID = 'FETCH_PROFILE_BY_TAG_ID',
  SET_PROFILE = 'SET_PROFILE',
  UPDATE_PROFILE = 'UPDATE_PROFILE',
  CLAIM_TAG = 'CLAIM_TAG',
  UPLOAD_PROFILE_PICTURE = 'UPLOAD_PROFILE_PICTURE',
  DELETE_PROFILE_PICTURE = 'DELETE_PROFILE_PICTURE',
  SET_PROFILE_PICTURE = 'SET_PROFILE_PICTURE',
  SET_AUTH_STATE = 'SET_AUTH_STATE',
  GET_PROFILE = 'GET_PROFILE',
}

export interface IBaseInitFirebaseAuthListenerAction {
  type: BaseActionTypes.INIT_FIREBASE_AUTH_LISTENER;
}

export interface IBaseLogoutAction {
  type: BaseActionTypes.LOGOUT;
}

export interface IBaseFetchCurrentUserAction {
  type: BaseActionTypes.FETCH_CURRENT_USER;
  token: string;
}

export interface IBaseSetCurrentUserAction {
  type: BaseActionTypes.SET_CURRENT_USER;
  currentUser: User | null;
}

export interface IBaseFetchTagAction {
  type: BaseActionTypes.FETCH_TAG;
  tagId: string;
}

export interface IBaseSetTagAction {
  type: BaseActionTypes.SET_TAG;
  tag: Tag;
}

export interface IBaseFetchProfileByTagIdAction {
  type: BaseActionTypes.FETCH_PROFILE_BY_TAG_ID;
  tagId: string;
}

export interface IBaseSetProfileAction {
  type: BaseActionTypes.SET_PROFILE;
  profile: Profile;
}

export interface IBaseUpdateProfileAction {
  type: BaseActionTypes.UPDATE_PROFILE;
  profileId: string;
  profile: Profile;
}

export interface IBaseClaimTagAction {
  type: BaseActionTypes.CLAIM_TAG;
  tagId: string;
}

export interface IBaseUploadProfilePictureAction {
  type: BaseActionTypes.UPLOAD_PROFILE_PICTURE;
  profileId: string;
  file: any;
}

export interface IBaseDeleteProfilePictureAction {
  type: BaseActionTypes.DELETE_PROFILE_PICTURE;
  profileId: string;
}

export interface IBaseSetProfilePictureAction {
  type: BaseActionTypes.SET_PROFILE_PICTURE;
  profilePicture: ProfilePicture | null;
}

export interface IBaseSetAuthStateAction {
  type: BaseActionTypes.SET_AUTH_STATE;
  authState: ThreeState;
}

export interface IBaseGetProfileAction {
  type: BaseActionTypes.GET_PROFILE;
  profileId: string;
}

export type BaseActions =
  | IBaseInitFirebaseAuthListenerAction
  | IBaseLogoutAction
  | IBaseFetchCurrentUserAction
  | IBaseSetCurrentUserAction
  | IBaseFetchTagAction
  | IBaseSetTagAction
  | IBaseFetchProfileByTagIdAction
  | IBaseSetProfileAction
  | IBaseUpdateProfileAction
  | IBaseClaimTagAction
  | IBaseUploadProfilePictureAction
  | IBaseDeleteProfilePictureAction
  | IBaseSetProfilePictureAction
  | IBaseSetAuthStateAction
  | IBaseGetProfileAction;

export type BaseThunk<ReturnType = Promise<any>> = ThunkAction<
  ReturnType,
  IBaseState,
  null,
  BaseActions
>;

export type BaseDispatch = ThunkDispatch<IBaseState, null, BaseActions>;

export const initFirebaseAuthListener: ActionCreator<BaseThunk> = () => {
  return async (dispatch) => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (!user) {
        api.clearTokenCallback();
        dispatch({
          type: BaseActionTypes.SET_CURRENT_USER,
          currentUser: null,
        });
        dispatch({
          type: BaseActionTypes.SET_AUTH_STATE,
          authState: ThreeState.FALSE,
        });
        return;
      }

      api.setTokenCallback(async () => {
        return await user.getIdToken();
      });
      dispatch(fetchCurrentUser());
    });
  };
};

export const logout: ActionCreator<BaseThunk> = () => {
  return async () => {
    await firebase.auth().signOut();
  };
};

export const fetchCurrentUser: ActionCreator<BaseThunk> = () => {
  return async (dispatch) => {
    const currentUser = await api.getCurrentUser();
    dispatch({
      type: BaseActionTypes.SET_CURRENT_USER,
      currentUser,
    });
    dispatch({
      type: BaseActionTypes.SET_AUTH_STATE,
      authState: ThreeState.TRUE,
    });
  };
};

export const fetchTag: ActionCreator<BaseThunk> = (tagId: string) => {
  return async (dispatch) => {
    const tag = await api.getTag(tagId);
    dispatch({
      type: BaseActionTypes.SET_TAG,
      tag,
    });
  };
};

export const fetchProfile: ActionCreator<BaseThunk> = (profileId: string) => {
  return async (dispatch) => {
    const profile = await api.getProfile(profileId);
    dispatch({
      type: BaseActionTypes.SET_PROFILE,
      profile,
    });
  };
};

export const fetchProfileByTagId: ActionCreator<BaseThunk> = (
  tagId: string
) => {
  return async (dispatch) => {
    const profile = await api.getProfileByTagId(tagId);
    dispatch({
      type: BaseActionTypes.SET_PROFILE,
      profile,
    });
  };
};

export const updateProfile: ActionCreator<BaseThunk> = (
  profileId: string,
  updatedProfile: Profile
) => {
  return async (dispatch) => {
    const profile = await api.updateProfile({
      profileId: profileId,
      profile: updatedProfile,
    });
    dispatch({
      type: BaseActionTypes.SET_PROFILE,
      profile,
    });
  };
};

export const claimTag: ActionCreator<BaseThunk> = (
  profileId: string,
  tagId: string
) => {
  return async (dispatch) => {
    const tag = await api.claimTag(profileId, tagId);
    dispatch({
      type: BaseActionTypes.SET_TAG,
      tag,
    });
  };
};

export const uploadProfilePicture: ActionCreator<BaseThunk> = (
  profileId: string,
  file: any
) => {
  return async (dispatch) => {
    const profilePicture = await api.uploadProfilePicture(profileId, file);
    dispatch({ type: BaseActionTypes.SET_PROFILE_PICTURE, profilePicture });
  };
};

export const deleteProfilePicture: ActionCreator<BaseThunk> = (
  profileId: string
) => {
  return async (dispatch) => {
    await api.deleteProfilePicture(profileId);
    dispatch({
      type: BaseActionTypes.SET_PROFILE_PICTURE,
      profilePicture: null,
    });
  };
};

export const getProfile: ActionCreator<BaseThunk> = (profileId: string) => {
  return async () => {
    return await api.getProfile(profileId);
  };
};
