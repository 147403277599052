import { lazy } from 'react';
import TagWrapperView from 'views/TagWrapperView';

const routes = [
  {
    exact: true,
    path: '/',
    component: lazy(() => import('../views/RootView')),
  },
  {
    exact: true,
    path: '/auth/login',
    component: lazy(() => import('../views/LoginView')),
  },
  {
    exact: true,
    path: '/auth/register',
    component: lazy(() => import('../views/RegisterView')),
  },
  {
    exact: true,
    path: '/auth/reset-pw',
    component: lazy(() => import('../views/ResetPasswordView')),
  },
  {
    exact: true,
    path: '/t/:tagId',
    wrapper: TagWrapperView,
    component: lazy(() => import('../views/TagView')),
  },
  {
    exact: true,
    path: '/t/:tagId/claim',
    component: lazy(() => import('../views/TagClaimView')),
  },
  {
    exact: true,
    path: '/t/:tagId/onboarding',
    component: lazy(() => import('../views/OnboardingView')),
  },
  {
    exact: true,
    path: '/t/:tagId/about',
    wrapper: TagWrapperView,
    component: lazy(() => import('../views/AboutView')),
  },
  {
    exact: true,
    path: '/t/:tagId/about/edit',
    wrapper: TagWrapperView,
    component: lazy(() => import('../views/AboutEditView')),
  },
  {
    exact: true,
    path: '/t/:tagId/contact',
    wrapper: TagWrapperView,
    component: lazy(() => import('../views/ContactView')),
  },
  {
    exact: true,
    path: '/t/:tagId/contact/edit',
    wrapper: TagWrapperView,
    component: lazy(() => import('../views/ContactEditView')),
  },
  {
    exact: true,
    path: '/t/:tagId/social',
    wrapper: TagWrapperView,
    component: lazy(() => import('../views/SocialView')),
  },
];

export default routes;
