import Tag from 'domain/models/Tag';
import ThreeState from 'domain/models/ThreeState';
import { Reducer } from 'redux';
import Profile from '../../domain/models/Profile';
import User from '../../domain/models/User';
import { BaseActions, BaseActionTypes } from '../actions/baseActions';

export interface IBaseState {
  currentUser: User | null;
  tag: Tag | null;
  profile: Profile | null;
  isEdit: boolean;
  authState: ThreeState;
}

const initialBaseState: IBaseState = {
  currentUser: null,
  tag: null,
  profile: null,
  isEdit: false,
  authState: ThreeState.UNKNOWN,
};

export const baseReducer: Reducer<IBaseState, BaseActions> = (
  state = initialBaseState,
  action
) => {
  switch (action.type) {
    case BaseActionTypes.SET_CURRENT_USER: {
      const isEdit =
        !!state.profile && state.profile.id === action.currentUser?.profileId; // TODO: find a better way to do this
      return { ...state, isEdit, currentUser: action.currentUser };
    }

    case BaseActionTypes.SET_TAG: {
      return { ...state, tag: action.tag };
    }

    case BaseActionTypes.SET_PROFILE: {
      const isEdit =
        !!state.currentUser &&
        state.currentUser.profileId === action.profile?.id; // TODO: find a better way to do this
      return { ...state, isEdit, profile: action.profile };
    }

    case BaseActionTypes.SET_PROFILE_PICTURE: {
      const profile = state.profile;

      return {
        ...state,
        profile: profile
          ? { ...profile, profilePicture: action.profilePicture }
          : null,
      };
    }

    case BaseActionTypes.SET_AUTH_STATE: {
      return { ...state, authState: action.authState };
    }

    default:
      return state;
  }
};
