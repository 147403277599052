import React from 'react';

interface IFooterLinkProps {
  prefix?: string;
  text: string;
  to: string;
}

const FooterLink = ({ prefix = '', text, to }: IFooterLinkProps) => {
  const prefixText = prefix ? (
    <span className="FooterLink__prefix">{prefix}</span>
  ) : null;
  return (
    <div className="FooterLink">
      {prefixText}
      <span className="FooterLink__link">
        <a className="FooterLink__link__a" href={to}>
          {text}
        </a>
      </span>
    </div>
  );
};

export default FooterLink;
