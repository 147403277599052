import { red } from '@material-ui/core/colors';
import { unstable_createMuiStrictModeTheme as createMuiTheme } from '@material-ui/core/styles';

// Add custom theme settings here
const theme = createMuiTheme({
  typography: {
    fontFamily: 'Rajdhani, sans-serif',
  },
  palette: {
    primary: {
      main: '#000',
    },
    secondary: {
      main: '#fff',
    },
    error: {
      main: red.A400,
    },
  },
});

export default theme;
