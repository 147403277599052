import firebase from 'firebase/app';
import 'firebase/auth';

const config = {
  apiKey: 'AIzaSyB8fGb3J70Y66oSWBXoEbMP4JtshBcDdeU',
  authDomain: 'aurocarry.firebaseapp.com',
  projectId: 'aurocarry',
  storageBucket: 'aurocarry.appspot.com',
  messagingSenderId: '1055428827233',
  appId: '1:1055428827233:web:bf3912ffe5be3a22147220',
  measurementId: 'G-C6XEY48PSG',
};

export const init = () => {
  firebase.initializeApp(config);
};

export default firebase;
