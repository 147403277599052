import ThreeState from 'domain/models/ThreeState';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'store';
import LoadingView from 'views/LoadingView';
import './App.scss';
import { init as initFirebase } from './helpers/firebase';
import AppRouter from './router/AppRouter';
import {
  BaseDispatch,
  initFirebaseAuthListener,
} from './store/actions/baseActions';

const App = () => {
  const authState = useSelector(
    (state: IAppState) => state.baseState.authState
  );
  const dispatch: BaseDispatch = useDispatch();

  useEffect(() => {
    initFirebase();
    dispatch(initFirebaseAuthListener());
  }, [dispatch]);

  // Ensure that the authentication state is resolved before displaying the app
  const app =
    authState === ThreeState.UNKNOWN ? <LoadingView /> : <AppRouter />;

  return (
    <div className="AppContainer">
      <div className="App">{app}</div>
    </div>
  );
};

export default App;
