import axios, { AxiosInstance } from 'axios';
import ProfilePicture from 'domain/models/ProfilePicture';
import Tag from 'domain/models/Tag';
import Profile from '../domain/models/Profile';
import User from '../domain/models/User';
import env from './env';

const API_URL = env.apiUrl;

class API {
  private axios: AxiosInstance;
  private tokenCallback: () => Promise<string | null>;

  constructor() {
    this.axios = axios.create({
      baseURL: API_URL,
    });
    this.tokenCallback = () => Promise.resolve(null);
  }

  async getToken() {
    const token = await this.tokenCallback();
    if (token == null) throw new Error('The token is null');
    this.logToken(token);
    return token;
  }

  logToken(token: string) {
    if (process.env.NODE_ENV === 'development') {
      console.log(token);
    }
  }

  setTokenCallback(callback: () => Promise<string>) {
    this.tokenCallback = callback;
  }

  clearTokenCallback() {
    this.tokenCallback = () => Promise.resolve(null);
  }

  async getCurrentUser(): Promise<User> {
    const token = await this.getToken();
    const headers = this.createHeaders(token);
    const result = await this.axios.get<User>('/users/me', { headers });

    return result.data;
  }

  async getTag(tagId: string): Promise<Tag> {
    return (await this.axios.get<Tag>(`/tags/${tagId}`)).data;
  }

  async getProfileByTagId(tagId: string): Promise<Profile> {
    return (await this.axios.get<Profile>(`/profiles?tagId=${tagId}`)).data;
  }

  async getProfile(profileId: string): Promise<Profile> {
    return (await this.axios.get<Profile>(`/profiles/${profileId}`)).data;
  }

  async updateProfile({
    profileId,
    profile: { onboarded, profileInfo },
  }: {
    profileId: string;
    profile: Profile;
  }): Promise<Profile> {
    const token = await this.getToken();
    const headers = this.createHeaders(token);
    const result = await this.axios.patch<Profile>(
      `/profiles/${profileId}`,
      {
        onboarded,
        profileInfo,
      },
      { headers }
    );

    return result.data;
  }

  async claimTag(profileId: string, tagId: string): Promise<Tag> {
    const token = await this.getToken();
    const headers = this.createHeaders(token);
    const result = await this.axios.post<Tag>(
      `/profiles/${profileId}/tags`,
      { id: tagId },
      { headers }
    );

    return result.data;
  }

  async uploadProfilePicture(
    profileId: string,
    file: any
  ): Promise<ProfilePicture> {
    const token = await this.getToken();
    const headers = this.createHeaders(token);

    const formData = new FormData();
    formData.append('picture', file);

    const result = await this.axios.post<ProfilePicture>(
      `/profiles/${profileId}/picture`,
      formData,
      { headers }
    );

    return result.data;
  }

  async deleteProfilePicture(profileId: string): Promise<ProfilePicture> {
    const token = await this.getToken();
    const headers = this.createHeaders(token);
    const result = await this.axios.delete<ProfilePicture>(
      `/profiles/${profileId}/picture`,
      { headers }
    );

    return result.data;
  }

  private createHeaders(token: string) {
    return {
      Authorization: `Bearer ${token}`,
    };
  }
}

const api = new API();

export default api;
