import React from 'react';
import { ReactComponent as Logo } from './logo.svg';

const WhiteTextLogo = () => {
  return (
    <div className="WhiteTextLogo">
      <Logo className="WhiteTextLogo__svg" />
    </div>
  );
};

export default WhiteTextLogo;
