import FooterLink from 'components/FooterLink';
import WhiteTextLogo from 'components/WhiteTextLogo';
import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="Footer">
      <Link to="/">
        <WhiteTextLogo />
      </Link>

      <div className="Footer__first_line">
        <FooterLink
          text="Terms of Service"
          to="https://aurocarry.com/policies/terms-of-service"
        />
        ·
        <FooterLink
          text="Privacy Policy"
          to="https://aurocarry.com/policies/privacy-policy"
        />
      </div>
      <div className="Footer__second_line">
        <FooterLink
          prefix={`Copyright ${new Date().getFullYear()} `}
          text="AURO Carry"
          to="https://aurocarry.com"
        />
      </div>
    </div>
  );
};

export default Footer;
