import { applyMiddleware, combineReducers, createStore, Store } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import { IBaseState, baseReducer } from './reducers/baseReducer';

export interface IAppState {
  baseState: IBaseState;
}

const rootReducer = combineReducers<IAppState>({
  baseState: baseReducer,
});

export function configureStore(): Store<IAppState, any> {
  return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
}
