import ITagIdParams from 'domain/interfaces/ITagIdParams';
import { useSnackbar } from 'notistack';
import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { IAppState } from 'store';
import {
  BaseDispatch,
  fetchProfile,
  fetchTag,
} from 'store/actions/baseActions';
import LoadingView from 'views/LoadingView';

interface ITagWrapperViewProps {
  children: ReactNode;
}

const TagWrapperView = ({ children }: ITagWrapperViewProps) => {
  const { tagId } = useParams<ITagIdParams>();
  const dispatch: BaseDispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const tag = useSelector((state: IAppState) => state.baseState.tag);
  const currentUser = useSelector(
    (state: IAppState) => state.baseState.currentUser
  );
  const profile = useSelector((state: IAppState) => state.baseState.profile);

  useEffect(() => {
    dispatch(fetchTag(tagId)).catch((error) => {
      console.error(error);
      enqueueSnackbar('Network error', { variant: 'error' });
    });
  }, [tagId, dispatch, enqueueSnackbar]);

  useEffect(() => {
    // Send this request only if there is a profile connected to this tag
    if (tag?.profileId) {
      dispatch(fetchProfile(tag.profileId)).catch((error) => {
        console.error(error);
        enqueueSnackbar('Network error', { variant: 'error' });
      });
    }
  }, [tag, dispatch, enqueueSnackbar]);

  // We need to wait for the tag to be loaded
  if (!tag) {
    return <LoadingView />;
  }

  // This tag is not claimed yet by anyone
  if (!tag.profileId) {
    return <Redirect to={`/t/${tagId}/claim`} />;
  }

  // We need to wait for the profile to be loaded
  if (!profile) {
    return <LoadingView />;
  }

  const isOwner = () => currentUser && profile.userId === currentUser.id;
  const notOnboarded = () => !profile.onboarded;

  return isOwner() && notOnboarded() ? (
    <Redirect to={`/t/${tagId}/onboarding`} />
  ) : (
    children
  );
};

export default TagWrapperView;
