interface IEnvironment {
  apiUrl: string;
  gaMeasurementId: string;
}

const env = window.__RUNTIME_CONFIG__;

console.log(env);

if (env.REACT_APP_DEVELOPMENT_API_URL === undefined) {
  throw new Error('REACT_APP_DEVELOPMENT_API_URL env variable is not set');
}

if (env.REACT_APP_TEST_API_URL === undefined) {
  throw new Error('REACT_APP_TEST_API_URL env variable is not set');
}

if (env.REACT_APP_PRODUCTION_API_URL === undefined) {
  throw new Error('REACT_APP_PRODUCTION_API_URL env variable is not set');
}

if (env.REACT_APP_DEVELOPMENT_GA_MEASUREMENT_ID === undefined) {
  throw new Error(
    'REACT_APP_DEVELOPMENT_GA_MEASUREMENT_ID env variable is not set'
  );
}

if (env.REACT_APP_TEST_GA_MEASUREMENT_ID === undefined) {
  throw new Error('REACT_APP_TEST_GA_MEASUREMENT_ID env variable is not set');
}

if (env.REACT_APP_PRODUCTION_GA_MEASUREMENT_ID === undefined) {
  throw new Error(
    'REACT_APP_PRODUCTION_GA_MEASUREMENT_ID env variable is not set'
  );
}

const development: IEnvironment = {
  apiUrl: env.REACT_APP_DEVELOPMENT_API_URL,
  gaMeasurementId: env.REACT_APP_DEVELOPMENT_GA_MEASUREMENT_ID,
};

const test: IEnvironment = {
  apiUrl: env.REACT_APP_TEST_API_URL,
  gaMeasurementId: env.REACT_APP_TEST_GA_MEASUREMENT_ID,
};

const production: IEnvironment = {
  apiUrl: env.REACT_APP_PRODUCTION_API_URL,
  gaMeasurementId: env.REACT_APP_PRODUCTION_GA_MEASUREMENT_ID,
};

const environments = {
  development,
  test,
  production,
};

export default environments[env.NODE_ENV];
